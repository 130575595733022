<template>
  <el-dialog :title="fileName" v-model="dialogVisible" :show-close="false" :close-on-press-escape="false"
    :close-on-click-modal="false" width="70%">
    <span>
      <wang-editor :value="value" :disable="false"></wang-editor>
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="closeDia">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, toRefs, watch } from "vue";
import WangEditor from '@/components/WangEnduitTry/index.vue';

export default {
  name: "checkFile",
  props: {
    fileName: {
      type: String,
      default: ''
    },
    diaStart: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    }
  },
  components: {
    WangEditor,
  },
  emits: ['setClose'],
  setup(prop, ctx) {
    watch(() => prop.diaStart, (val, prevVal) => {
      data.dialogVisible = val
    })
    let data = reactive({
      dialogVisible: false
    })
    let closeDia = () => { data.dialogVisible = false; ctx.emit('setClose', false) }
    return {
      ...toRefs(data),
      closeDia
    }
  }
}
</script>

<style scoped></style>